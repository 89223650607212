.App {
  min-height: 100vh;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
}

.download-link {
  font-size: large;
  font-weight: bold;
}

/* styles.css */

/* For WebKit (Chrome, Safari) */

h1, h2, h3, h4, p, a, span, div, label {
  font-family: 'Poppins', sans-serif;
}