@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* styles.css or your preferred CSS file */
.loading-overlay1 {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  pointer-events: auto; /* Enable pointer events for the overlay */
}

.loading-content1 {
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-quote {
  color: black;
  font-size: 13px;
  font-style: italic;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.loading-text {
  color: black;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

/* You can customize the loading icon styles */
.loading-icon1 {
  animation: zoomInOut 1.5s ease-in-out infinite; /* Apply the animation to the loading overlay */
}
